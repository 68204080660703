<template>
  <div>
    <div style="display: flex">
      <el-button type="primary" @click="add(null)">新增</el-button>
    </div>
    <el-table
      :data="list"
      border
      :max-height="maxHeight"
      style="margin-top: 20px"
      v-loading="loading"
    >
      <el-table-column prop="id" label="商品编号"></el-table-column>
      <el-table-column prop="name" label="商品名称"></el-table-column>
      <el-table-column prop="logoUrl" label="商品封面图" align="center">
        <template slot-scope="scope">
          <el-image
            :src="scope.row.logoUrl"
            fit="contain"
            style="width: 100px"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="countBuy" label="购买数量"></el-table-column>
      <el-table-column prop="salesCount" label="展示销量"></el-table-column>
      <el-table-column prop="sort" label="排序值"></el-table-column>
      <el-table-column prop="points" label="赠送积分值"></el-table-column>
      <el-table-column prop="statusDes" label="审核状态"></el-table-column>
      <el-table-column prop="onShelf" label="是否上架">
        <template slot-scope="scope">
          {{ scope.row.onShelf ? "已上架" : "未上架" }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="detail1(scope.row)">详情</el-button>
          <el-button size="mini" type="text" @click="add(scope.row)">编辑</el-button>
          <el-button size="mini" type="text" @click="updateShelf(scope.row)">{{
            scope.row.onShelf ? "下架" : "上架"
          }}</el-button>
          <el-button size="mini" type="text" @click="updateSendPoints(scope.row)">{{
            scope.row.sendPoints ? "关闭赠送积分" : "开启赠送积分"
          }}</el-button>
          <el-button size="mini" type="text" @click="handleSetPoints(scope.row)">设置赠送积分值</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="totalRows"
      :current-page="page"
      :page-size="20"
      style="margin-top: 20px"
      @current-change="handleChangePage"
    >
    </el-pagination>
    <el-dialog
      title="设置赠送积分值"
      width="50%"
      :visible.sync="dialogVisible">
      <el-input v-model="points" placeholder="请输入内容"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updatePoints()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import SpuApi from "../../request/SpuApi";
export default {
  data() {
    return {
      page: 1,
      list: [],
      points: 0,
      detail: {},
      totalRows: 0,
      loading: false,
      dialogVisible: false,
      maxHeight: document.documentElement.clientHeight - 250,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      SpuApi.myList({
        pageNo: this.page,
        pageSize: 20,
        pointsGoods: false,
        spuType: "COURSE_GOODS",
      })
        .then((res) => {
          this.loading = false;
          if (res.success) {
            this.list = res.data;
            this.totalRows = res.totalRows;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleChangePage(val) {
      this.page = val;
      this.loadData();
    },
    add(val) {
      if (val) {
        this.$router.push({ path: "/spu/add", query: { id: val.id } });
      } else {
        this.$router.push("/spu/add");
      }
    },
    detail1(val) {
      this.$router.push({ path: "/spu/detail", query: { id: val.id } });
    },
    updateShelf(val) {
      SpuApi.updateShelf({
        onShelf: !val.onShelf,
        spuId: val.id,
      }).then((res) => {
        if (res.success) {
          this.$message.success("更改状态成功");
          val.onShelf = !val.onShelf;
        }
      });
    },
    updateSendPoints(val) {
      const spu = val;
      spu.type = "COURSE_GOODS";
      spu.userId = this.$store.getters.getUserInfo.id;
      spu.skuList[0].marketPrice = val.marketPrice * 100;
      spu.skuList[0].realPrice = val.realPrice * 100;
      spu.skuList[0].propertyId = val.skuList[0].specPropertyId;
      spu.skuList[0].id = val.skuList[0].skuId;
      SpuApi.update({
        ...spu,
        spuId: val.id,
        sendPoints: !val.sendPoints
      }).then((res) => {
        if (res.success) {
          this.$message.success("更新成功");
          this.loadData()
        }
      });
    },
    updatePoints() {
      const spu = this.detail;
      spu.type = "COURSE_GOODS";
      spu.userId = this.$store.getters.getUserInfo.id;
      spu.skuList[0].marketPrice = this.detail.marketPrice * 100;
      spu.skuList[0].realPrice = this.detail.realPrice * 100;
      spu.skuList[0].propertyId = this.detail.skuList[0].specPropertyId;
      spu.skuList[0].id = this.detail.skuList[0].skuId;
      SpuApi.update({
        ...spu,
        spuId: this.detail.id,
        points: +this.points,
      }).then((res) => {
        if (res.success) {
          this.dialogVisible = false;
          this.$message.success("更新成功");
          this.loadData()
        }
      });
    },
    handleSetPoints(row) {
      this.detail = row;
      this.points = row.points;
      this.dialogVisible = true;
    }
  },
};
</script>

<style></style>
