const baseUrl = "/zp-mobile/v1/spu/"
import http from './http';
class SpuApi {
  static list(param) {
    return http.post(baseUrl + "tourist/list", param)
  }
  static myList(param) {
    return http.post(baseUrl + "my-list", param)
  }
  static insert(param) {
    return http.post(baseUrl + "insert", param)
  }
  static detail(param) {
    return http.post(baseUrl + "detail", param)
  }
  static update(param) {
    return http.post(baseUrl + "update", param)
  }
  static updateShelf(param) {
    return http.post(baseUrl + "update-shelf", param)
  }
}
export default SpuApi;